<template>
  <b-card>
    <site-form v-if="site" :initial-site="site"/>
  </b-card>

</template>

<script>
import SiteForm from "@/components/SiteForm";

export default {
  name: "SiteAdd",
  components: {SiteForm},
  data() {
    return {
      site: false
    }
  },
  mounted() {
    let _this = this;
    this.$store.dispatch('siteModule/fetch', {
      site_id: this.$route.params.id
    }).then(function (response) {
      _this.site = response.data;
    })
  }
}
</script>

<style scoped>

</style>